/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('card', {
      parent: 'root',
      url: '/card',
      params: {
        showDetail: false
      },
      views: {
        '': {
          templateUrl: 'sections/card/templates/acp-card.ng.html',
          controller: 'AcpCardSectionCtrl'
        },
        'my-card@card': {
          template: '<acp-card-landing></acp-card-landing>'
        },
        'virtual-card@card': {
          template:
            '<acp-virtual-cards-container></acp-virtual-cards-container>'
        },
        'digital-wallet@card': {
          template: '<acp-card-digital-wallet></acp-card-digital-wallet>'
        },
        'cards-history@card': {
          template: '<acp-card-history></acp-card-history>'
        }
      },
      resolve: {
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.section.card" */ 'sections/card'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('processing', {
      parent: 'public',
      url: '/custom-card?CardImageId',
      template: '<acp-card-order-processing />',
      resolve: {
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.section.card" */ 'sections/card'
          ).then($ocLazyLoad.loadModule);
        }
      }
    });
}

export default states;
